<template>
    <div>
        <mobile-layout source="img" tab="3">

            <v-img src="../../assets/guide.jpeg"></v-img>
            <!-- <v-container>
                <v-row justify="center" no-gutters class="flex mt-10">
                    <v-col cols="12" align="center" justify="center">
                        <h1>参会指南</h1>
                    </v-col>
                    <span
                        class="
                            caption
                            blue-grey--text
                            text--lighten-2
                            block
                            text-uppercase
                        "
                        >Participation Guide</span
                    >
                </v-row>

                <v-row
                    justify="start"
                    no-gutters
                    class="
                        flex
                        mt-10
                        light-blue--text
                        text--lighten-4 text-left
                    "
                >
                    <v-tabs
                        left
                        dark
                        slider-color="transparent"
                        hide-slider
                        background-color="transparent"
                    >
                        <v-tab class="text-left">大会导览</v-tab>
                        <v-tab>福州特色</v-tab>

                        <v-tab-item v-for="n in 2" :key="n">
                            <v-img
                                height="350"
                                src="../../assets/agenda_item_2.png"
                            ></v-img>
                        </v-tab-item>
                    </v-tabs>
                </v-row>
            </v-container> -->
        </mobile-layout>
    </div>
</template>

<script>
import MobileLayout from '../../components/MobileLayout.vue'
export default {
    components: { MobileLayout },
    name: 'Guide',

    data: () => ({
        model: 0,
        images: [
            'http://game.gtimg.cn/images/up/2019/m/img/guest-4.7a7a8bf.jpg',
            'http://game.gtimg.cn/images/up/2019/m/img/guest-4.7a7a8bf.jpg',
            'http://game.gtimg.cn/images/up/2019/m/img/guest-4.7a7a8bf.jpg',
        ],
    }),
}
</script>

<style lang="scss" scoped></style>
